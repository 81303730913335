import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    list: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('inputs', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    show: (cntx, { id }) => {
        return new Promise((resolve, reject) => {
            axios.get(`inputs/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
    create: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .post('inputs', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    update: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`inputs/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    remove: (cntx, id) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(`inputs/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    seasons: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('seasons', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    prices: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('input_prices', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    levels: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('levels', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    units: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('units', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    currencies: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .get('currencies', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    savePrice: (cntx, params) => {
      return new Promise((resolve, reject) => {
        axios
          .post('pricing', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
